
import { defineComponent, onMounted, ref } from "vue";
import { ApiBase } from "@/core/api";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { TaggingItem } from "@/core/directive/interface/common";

interface Filter {
  order_number: string;
  customer_id: string;
  order_date_start: string;
  order_date_end: string;
  order_type: string;
}

export default defineComponent({
  name: "filter-sales-orders-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<Filter>({
      order_number: "",
      customer_id: "",
      order_date_start: "",
      order_date_end: "",
      order_type: "1",
    });

    const options = ref({
      customer: [] as TaggingItem[],
      customerLoading: false,
      order_type: [] as TaggingItem[],
    });

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["order_tracking_order_type"],
      });
      if (data.code == 0) {
        options.value.order_type = data.data.order_tracking_order_type.items;
      }
    };

    const getCustomerOptions = async (query: string) => {
      options.value.customerLoading = true;
      const { data } = await ApiBase.getCustomerSourceData({ name: query });
      options.value.customerLoading = false;
      if (data.code == 0) {
        options.value.customer = data.data;
      }
    };

    const debounceCustomerSearch = _.debounce(getCustomerOptions, 1000);

    const searchCustomerItems = (query: string) => {
      debounceCustomerSearch(query);
    };

    onMounted(() => {
      getTaggingData();
    });

    return {
      t,
      formData,
      formRef,
      submit,
      handleReset,
      options,
      searchCustomerItems,
    };
  },
});
