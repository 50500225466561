
import { defineComponent, onMounted, ref, watch } from "vue";
import { ApiOrderTracking } from "@/core/api";
import _ from "lodash";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { CommonHtmlType } from "@/core/directive/type/common";
import { hideModal, modalHideListener } from "@/core/helpers/dom";

export default defineComponent({
  name: "mb-order-routing-order-tracking-exception",
  emits: ["update-list", "reset-form"],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    data_ids: {
      type: String,
      default: "",
    },
    delay_days: {
      type: Number,
      default: 0,
    },
    delay_reason: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showServerErrorMsg,
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
    } = mixin();

    const loading = ref(false);

    const editExceptionRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);

    const formData = ref({
      data_ids: "",
      delay_days: 0,
      delay_reason: "",
    });

    const rules = ref({
      data_ids: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      delay_days: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      delay_reason: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    watch(
      () => props.dialogVisible,
      (newValue) => {
        if (newValue) {
          formData.value.data_ids = props.data_ids;
          formData.value.delay_days = props.delay_days;
          formData.value.delay_reason = props.delay_reason;
        }
      }
    );

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiOrderTracking.delayOrderTracking(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  emit("update-list");
                  handleDiscard();
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const handleDiscard = () => {
      resetForm();
      hideModal(editExceptionRef.value);
    };

    onMounted(() => {
      modalHideListener(editExceptionRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      props,
      editExceptionRef,
      formRef,
      submitButton,
      loading,
      formData,
      rules,
      handleDiscard,
      submit,
    };
  },
});
