
import {
  defineComponent,
  ref,
  onMounted,
  onActivated,
  computed,
  onUpdated,
} from "vue";
import { MBDatatable } from "magicbean-saas-common";
import FilterDropdown from "@/views/mb-order-routing/order-tracking/FilterDropdown.vue";
import exceptionModal from "./exceptionModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiOrderTracking } from "@/core/api";
import moment from "moment";
import _ from "lodash";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { SalesOrderTable } from "@/core/directive/interface/salesOrder";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { NumberOrString } from "@/core/directive/type/common";
import {
  getSalesOrderFulfillmentStatus,
  getSalesOrderStatus,
  getSalesOrderTypeOptions,
} from "@/core/directive/function/salesOrder";
import store from "@/store";
import ImportModal from "./ImportModal.vue";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import {
  commonChangeFilterAddDate,
  commonExportFile,
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";

export default defineComponent({
  name: "mb-order-routing-order-tracking",
  components: {
    MBDatatable,
    FilterDropdown,
    exceptionModal,
    ImportModal,
    ProductInfo,
    SkuInfo,
    PermissionCommon,
  },
  setup() {
    const { t } = useI18n();
    const { showServerErrorMsg } = mixin();
    const route = useRoute();

    const loading = ref(true);
    const dialogVisible = ref(false);
    const data_ids = ref("");
    const delay_days = ref(0);
    const delay_reason = ref("");
    const tableData = ref<Array<SalesOrderTable>>([]);
    const checkedOrderTracking = ref<Array<number>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const disabledExportProgress = ref<boolean>(false);
    const disabledExportPrintProgress = ref<boolean>(false);
    const disabledForPurchaserReport = ref<boolean>(false);
    const disabledForPurchaserReportPDF = ref<boolean>(false);
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "created_at", direction: "desc" },
    ]);

    const options = ref({
      loadingProducts: true,
      order_type: "1",
    });

    const tableHeader = ref([
      {
        name: t("orderTracking.order"),
        key: "order",
        sortable: false,
      },
      {
        name: t("orderTracking.product"),
        key: "product",
        sortable: false,
      },
      {
        name: t("orderTracking.sku"),
        key: "sku",
        sortable: false,
      },
      {
        name: t("orderTracking.qty"),
        key: "ordered_qty",
        sortable: false,
        align: "right",
      },
      {
        name: t("orderTracking.current"),
        key: "current",
        sortable: false,
      },
      {
        name: t("orderTracking.nextStep"),
        key: "next_step",
        sortable: false,
      },
      {
        name: t("orderTracking.estimatedDeliveryTime"),
        key: "estimated_delivery_time",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const init = () => {
      setModuleBCN(t, route);
      getOrderTrackingList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    const checkedIsCustomerFilter = computed(() => {
      let flag = true;
      if (filterOptions.value.length > 0) {
        filterOptions.value.map((item) => {
          if (item.field == "customer_id" && item.value != "") {
            flag = false;
          }
        });
      }
      return flag;
    });

    const fileExportProgress = () => {
      if (disabledExportProgress.value) true;
      disabledExportProgress.value = true;
      ApiOrderTracking.exportProgress({
        search_value: search.value,
        filter_group: search.value.trim() !== "" ? [] : filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
        order_type:
          search.value.trim() !== "" ? undefined : options.value.order_type,
      })
        .then((data) => {
          disabledExportProgress.value = false;
          commonExportFile(data);
        })
        .catch((error) => {
          disabledExportProgress.value = false;
          console.log(error);
        });
    };

    const fileExportPrintProgress = () => {
      if (disabledExportPrintProgress.value || filterOptions.value.length == 0)
        return;
      disabledExportPrintProgress.value = true;
      ApiOrderTracking.printProgress({
        search_value: search.value,
        filter_group: search.value.trim() !== "" ? [] : filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
        order_type:
          search.value.trim() !== "" ? undefined : options.value.order_type,
      })
        .then((data) => {
          disabledExportPrintProgress.value = false;
          commonExportFile(data);
        })
        .catch((error) => {
          disabledExportPrintProgress.value = false;
          console.log(error);
        });
    };

    const fileForPurchaserReport = () => {
      if (disabledForPurchaserReport.value || checkedIsCustomerFilter.value)
        return;
      disabledForPurchaserReport.value = true;
      ApiOrderTracking.exportReport({
        search_value: search.value,
        filter_group: search.value.trim() !== "" ? [] : filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
        order_type:
          search.value.trim() !== "" ? undefined : options.value.order_type,
      })
        .then((data) => {
          disabledForPurchaserReport.value = false;
          commonExportFile(data);
        })
        .catch((error) => {
          disabledForPurchaserReport.value = false;
          console.log(error);
        });
    };

    const fileForPurchaserReportPDF = () => {
      if (disabledForPurchaserReportPDF.value || checkedIsCustomerFilter.value)
        return;
      disabledForPurchaserReportPDF.value = true;
      ApiOrderTracking.printReport({
        search_value: search.value,
        filter_group: search.value.trim() !== "" ? [] : filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
        order_type:
          search.value.trim() !== "" ? undefined : options.value.order_type,
      })
        .then((data) => {
          disabledForPurchaserReportPDF.value = false;
          commonExportFile(data);
        })
        .catch((error) => {
          disabledForPurchaserReportPDF.value = false;
          console.log(error);
        });
    };

    const getOrderTrackingList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiOrderTracking.getOrderTrackingList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: search?.trim() !== "" ? [] : filterOptions,
        sort_orders: sortOptions,
        order_type:
          search?.trim() !== "" ? undefined : options.value.order_type,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
            // getProductsData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getOrderTrackingList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsSelectChange = (selection) => {
      let ids: number[] = [];
      selection.forEach((item) => {
        ids.push(item.id);
      });
      checkedOrderTracking.value = ids;
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getOrderTrackingList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const getLabel = (val, key) => {
      return options.value[key].find((item) => {
        return item.value == val;
      })?.label;
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getOrderTrackingList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (item == "customer_id") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          } else if (item == "order_date_start") {
            filterArr.push({
              field: "order_date",
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "order_date_end") {
            filterArr.push({
              field: "order_date",
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else if (item == "order_type") {
            // filterArr.push({
            //   field: item,
            //   value: val[item],
            //   condition: "eq",
            // });
            options.value.order_type = val[item];
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getOrderTrackingList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getOrderTrackingList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      options.value.order_type = "1";
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getOrderTrackingList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const openModal = (item) => {
      if (item.data_id) {
        dialogVisible.value = true;
        data_ids.value = item.data_ids;
        delay_days.value = item.delay_days;
        delay_reason.value = item.delay_reason;
      }
    };

    const closeModal = () => {
      data_ids.value = "";
      delay_days.value = 0;
      delay_reason.value = "";
      dialogVisible.value = false;
    };

    const getOrderType = computed(() => {
      return (status: number) => {
        return getSalesOrderTypeOptions(status, t);
      };
    });

    const orderStatus = computed(() => {
      return (status: string) => {
        return getSalesOrderStatus(status, t);
      };
    });

    const fulfillmentStatus = computed(() => {
      return (status: NumberOrString) => {
        return getSalesOrderFulfillmentStatus(status, t);
      };
    });

    const splitString = computed(() => {
      return (string) => {
        return string.split(",");
      };
    });

    const isBigCurrentDate = computed(() => {
      return (date) => {
        const nowDate = moment(moment(), "YYYY-MM-DD").startOf("day").valueOf();
        const nextDate = moment(date, "YYYY-MM-DD").startOf("day").valueOf();
        return nextDate && nowDate > nextDate;
      };
    });

    const isBigDeliyDate = computed(() => {
      return (edeliyDate, adeliyDate) => {
        const eDate = moment(edeliyDate, "YYYY-MM-DD").startOf("day").valueOf();
        const aDate = moment(adeliyDate, "YYYY-MM-DD").startOf("day").valueOf();
        return eDate && aDate && eDate > aDate;
      };
    });

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      dialogVisible,
      data_ids,
      delay_days,
      delay_reason,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      filterOptions,
      disabledExportProgress,
      disabledExportPrintProgress,
      disabledForPurchaserReport,
      disabledForPurchaserReportPDF,
      options,
      getOrderTrackingList,
      onRowsSelectChange,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      getLabel,
      searchItems,
      handleFilter,
      handleFilterReset,
      updateList,
      removeSearch,
      openModal,
      closeModal,
      getOrderType,
      orderStatus,
      fulfillmentStatus,
      splitString,
      checkedIsCustomerFilter,
      fileExportProgress,
      fileExportPrintProgress,
      fileForPurchaserReport,
      fileForPurchaserReportPDF,
      isBigCurrentDate,
      isBigDeliyDate,
    };
  },
});
