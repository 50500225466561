
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import { ApiOrderTracking } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { CommonHtmlType } from "@/core/directive/type/common";
import { Upload } from "@element-plus/icons-vue";
import { ElFile } from "element-plus/es/components/upload/src/upload.type";
import { ElUpload } from "element-plus";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "import-order-tracking-batch-delay-modal",
  components: {},
  emits: ["update-list"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const importOrderTrackingRef = ref<CommonHtmlType>(null);
    const uploadRef = ref<InstanceType<typeof ElUpload>>();

    const uploadUrl = ApiOrderTracking.importBatchDelay();

    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });

    const { showServerErrorMsg } = mixin();

    const formData = ref({
      importFileList: [],
      importFile: {} as ElFile,
    });

    const rules = ref({
      importFileList: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({});

    const setErrorUploadFile = () => {
      formData.value.importFileList.forEach((item: any, index) => {
        uploadRef.value!.handleRemove(item, formData.value.importFile);
      });
      uploadRef.value!.clearFiles();
      uploadRef.value!.handleStart(formData.value.importFile);
    };

    const handleUploadError = () => {
      loading.value = false;
      setErrorUploadFile();
    };

    const handleUploading = () => {
      loading.value = true;
    };

    const handleUploadChange = (file, files) => {
      formData.value.importFileList = files;
      formData.value.importFile = file.raw;
    };

    const handleUploadExced = (files, uploadFiles) => {
      formData.value.importFileList.forEach((item: any, index) => {
        uploadRef.value!.handleRemove(item, formData.value.importFile);
      });
      uploadRef.value!.handleStart(files[0]);
    };

    const handleUploadSuccess = (res, file) => {
      loading.value = false;
      if (res.code == 0) {
        Swal.fire({
          text: t("orderTracking.importSuccess"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          resetForm();
          emit("update-list");
          hideModal(importOrderTrackingRef.value);
        });
      } else {
        showServerErrorMsg(res);
        setErrorUploadFile();
      }
    };

    const handleDiscard = () => {
      hideModal(importOrderTrackingRef.value);
      resetForm();
    };

    const resetForm = () => {
      uploadRef.value!.clearFiles();
    };

    return {
      t,
      loading,
      formData,
      importOrderTrackingRef,
      rules,
      options,
      uploadRef,
      handleDiscard,
      resetForm,
      handleUploadError,
      handleUploading,
      handleUploadChange,
      handleUploadExced,
      handleUploadSuccess,
      uploadUrl,
      uploadHeaders,
      Upload,
    };
  },
});
