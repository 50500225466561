import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "modal_import_order_tracking",
  ref: "importOrderTrackingRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  id: "kt_modal_import_order_tracking_header"
}
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = {
  "data-bs-dismiss": "modal",
  class: "btn btn-icon btn-sm btn-active-icon-primary"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1" }
const _hoisted_8 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_9 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_import_order_tracking_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_import_order_tracking_header",
  "data-kt-scroll-wrappers": "#kt_modal_import_order_tracking_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-md-12" }
const _hoisted_12 = { class: "form-group mb-7" }
const _hoisted_13 = { class: "fs-6 fw-bold mb-2 required" }
const _hoisted_14 = { class: "el-form-item__content" }
const _hoisted_15 = { class: "el-upload__tip text-red" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t("orderTracking.import")), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.t("orderTracking.selectFile")), 1),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_el_upload, {
                      "file-list": _ctx.formData.importFileList,
                      action: _ctx.uploadUrl,
                      headers: _ctx.uploadHeaders,
                      limit: 1,
                      accept: ".xls,.xlsx",
                      "on-error": _ctx.handleUploadError,
                      "on-progress": _ctx.handleUploading,
                      "on-change": _ctx.handleUploadChange,
                      "on-exceed": _ctx.handleUploadExced,
                      "on-success": _ctx.handleUploadSuccess,
                      ref: "uploadRef"
                    }, {
                      tip: _withCtx(() => [
                        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.t("orderTracking.uploadTip")), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "primary",
                          icon: _ctx.Upload
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("orderTracking.clickToUpload")), 1)
                          ]),
                          _: 1
                        }, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["file-list", "action", "headers", "on-error", "on-progress", "on-change", "on-exceed", "on-success"])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 512))
}